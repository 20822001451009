import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Tapsody.png";
import projImg2 from "../assets/img/trashsmart.png";
import projImg3 from "../assets/img/Bdtkvirtous.png";
import projImg4 from "../assets/img/myownarmy.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const projects = [
    {
      title: "A better way to book, record, practice, and play.",
      description: "A community app for musicians and music industry professionals.evelopment",
      imgUrl: projImg1,
    },
    {
      title: "Plastic recycling in Ghana - a collab with Engineers Without Borders-NL & UNICEF.",
      description: "A plastic pick-up scheduling app for TrashSmart, Ghana. ",
      imgUrl: projImg2,
    },
    {
      title: "Stylish sports apparel for women, children and men.",
      description: "Webshop development and programming.",
      imgUrl: projImg3,
    },
    {
      title: "Rotterdam based progressive / alternative  rock at its best.",
      description: "Musician desktop and mobile website design and build.",
      imgUrl: projImg4,
    },
    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>At Virtuous-IT, we are dedicated to delivering creative tech solutions that serve as catalysts for positive change.<br></br>Our projects are carefully curated to empower social impact startups and small businesses, leveraging the transformative potential of technology to drive meaningful and lasting impact. From web and mobile apps to tailor-made solutions, we are committed to contributing to a brighter, tech-enabled future for organizations making a difference in the world.</p>
                  <Row>
                    {projects.map((project, index) => (
                      <ProjectCard key={index} sm={6} md={6} {...project} />
                    ))}
                  </Row>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
